import React from 'react';

import { Site } from '../components/Site';
import { Container } from '../components/ui/Container';
import styled from 'styled-components';

const NotFound = styled.div`
	h2, p {
		text-align: center;
	}

	h2 {
		border: none;
	}
`;

const seo = {
  title: '404',
};

const NotFoundPage = ({ ...other }) => {
  return (
    <Site seo={seo} {...other}>
      <Container maxWidth={1600}>
        <NotFound>
					<h2>Page not found.</h2>
					<p>The page you're looking for either moved or no longer exists.</p>
				</NotFound>
      </Container>
    </Site>
  );
};

export default NotFoundPage;
